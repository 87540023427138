@charset "UTF-8";

@import "../core/variables";

$color_1: #FAA14F;
$color_2: #fff;
$color_3: #555;
$color_4: #888;
$font-family_1: "Inter", "Noto Sans JP", sans-serif;
$mobile-breakpoint: 768px;
$desktop-breakpoint: 769px;

@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Noto+Sans+JP:wght@400;600&display=swap";
@keyframes animation {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
  70% {
    opacity: 1;
    transform: scale(1.05);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes bannerAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  60% {
    opacity: 1;
    transform: scale(1);
  }
  70% {
    opacity: 1;
    transform: scale(1.05);
  }
  80% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


/*# sourceMappingURL=style.css.map */
.sp {
  display: none !important;
}
.effect.btn {
  opacity: 0;
}
.effect.btn.anim {
  animation: 1s cubic-bezier(0.65, 0, 0.35, 1) 0s forwards animation;
}
body {
  font-family: $font-family_1 !important;
  letter-spacing: 0.03em;
}
body.login {
  header {
    .btn-login {
      display: none !important;
    }
  }
  #float-nav {
    .nomember {
      display: none !important;
    }
  }
}
body.logout {
  header {
    .usermenu {
      display: none !important;
    }
    .usermenu-sp {
      display: none !important;
    }
  }
  #float-nav {
    .top-box {
      display: none;
    }
    .member {
      display: none !important;
    }
  }
}
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-bottom: 0px;
  h1 {
    position: absolute;
    top: 15px;
    left: 36px;
    width: 95px;
    a {
      display: block;
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .r-box {
    position: absolute;
    top: 0;
    right: 36px;
    nav {
      position: relative;
      display: flex;
      ul.gnav-li {
        position: relative;
        padding-top: 24px;
        display: flex;
        li {
          position: relative;
          padding-left: 28px;
          font-size: 16px;
          letter-spacing: 0em;
          &:before {
            position: absolute;
            content: "";
            top: 2px;
            left: 0;
            width: 20px;
            height: 20px;
            background: #FEF4EB;
            border-radius: 50%;
          }
          &:after {
            position: absolute;
            content: "";
            top: 2px;
            left: 0;
            width: 20px;
            height: 20px;
            background: url("/assets/images/202309/icon_nav_event.svg") center center no-repeat;
          }
          &:nth-child(n+2) {
            margin-left: 24px;
          }
        }
        li.nav-event {
          &:after {
            background: url("/assets/images/202309/icon_nav_event.svg") center center no-repeat;
          }
        }
        li.nav-about {
          &:after {
            background: url("/assets/images/202309/icon_nav_about.png") center center no-repeat;
          }
        }
        li.nav-blog {
          &:after {
            background: url("/assets/images/202309/icon_nav_blog.svg") center center no-repeat;
          }
        }
        li.nav-help {
          &:after {
            background: url("/assets/images/202309/icon_nav_help.svg") center center no-repeat;
          }
        }
      }
      ul.btn-login {
        position: relative;
        display: flex;
        margin-left: 32px;
        padding-top: 14px;
        li {
          a {
            display: block;
            position: relative;
            width: 92px;
            height: 44px;
            border-radius: 50px;
            text-align: center;
            border: 1px solid #ddd;
            box-sizing: border-box;
            padding: 10px 0 10px;
            color: $color_1;
            letter-spacing: 0em;
            color: $color_1 !important;
          }
          &:nth-child(n+2) {
            margin-left: 8px;
          }
        }
        li.regist {
          a {
            background: #FAA14F;
            border: 1px solid #FAA14F;
            color: $color_2;
            color: $color_2 !important;
          }
        }
      }
      .usermenu {
        position: relative;
        width: 180px;
        border: 1px solid #ddd;
        border-radius: 50px;
        height: 48px;
        padding: 4px 4px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-top: 12px;
        margin-left: 32px;
        cursor: pointer;
        &:after {
          position: absolute;
          content: "";
          top: 20px;
          right: 17px;
          background: url("/assets/images/202309/icon_pulldown.svg") center center no-repeat;
          width: 8px;
          height: 5px;
          background-size: contain;
        }
        .image {
          position: relative;
          width: 38px;
          overflow: hidden;
          border-radius: 50%;
          flex-shrink: 0;
          margin-right: 8px;
          img {
            object-fit: cover;
            width: 38px;
            height: 38px;
          }
        }
        .name {
          font-size: 14px;
          letter-spacing: 0em;
        }
      }
      .usermenu-sp {
        position: relative;
        top: 13px;
        width: 40px;
        flex-shrink: 0;
        cursor: pointer;
        margin-left: 15px;
        a {
          display: block;
          width: 100%;
          height: 100%;
          overflow: hidden;
          border-radius: 50%;
          img {
            object-fit: cover;
            width: 40px;
            height: 40px;
          }
        }
      }
      .btnNavi {
        position: relative;
        top: 18px;
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        margin-left: 12px;
        cursor: pointer;
        transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
        z-index: 10;
        padding: 6px 0;
        span {
          position: relative;
          display: block;
          width: 20px;
          height: 2px;
          background: #888;
          transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
          margin: 0 auto;
          &:nth-child(n+2) {
            margin: 6px auto 0;
          }
        }
      }
      .btnNavi.active {
        span {
          &:nth-child(1) {
            transform: translate(0px, 8px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translate(0px, -8px) rotate(-45deg);
          }
        }
      }
    }
  }
}
#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 72px;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-bottom: 0px;
  h1 {
    position: absolute;
    top: 15px;
    left: 36px;
    width: 95px;
    a {
      display: block;
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .r-box {
    position: absolute;
    top: 0;
    right: 36px;
    nav {
      position: relative;
      display: flex;
      ul.gnav-li {
        position: relative;
        padding-top: 24px;
        display: flex;
        li {
          position: relative;
          padding-left: 28px;
          font-size: 16px;
          letter-spacing: 0em;
          &:before {
            position: absolute;
            content: "";
            top: 2px;
            left: 0;
            width: 20px;
            height: 20px;
            background: #FEF4EB;
            border-radius: 50%;
          }
          &:after {
            position: absolute;
            content: "";
            top: 2px;
            left: 0;
            width: 20px;
            height: 20px;
            background: url("/assets/images/202309/icon_nav_event.svg") center center no-repeat;
          }
          &:nth-child(n+2) {
            margin-left: 24px;
          }
        }
        li.nav-event {
          &:after {
            background: url("/assets/images/202309/icon_nav_event.svg") center center no-repeat;
          }
        }
        li.nav-about {
          &:after {
            background: url("/assets/images/202309/icon_nav_about.png") center center no-repeat;
          }
        }
        li.nav-blog {
          &:after {
            background: url("/assets/images/202309/icon_nav_blog.svg") center center no-repeat;
          }
        }
        li.nav-help {
          &:after {
            background: url("/assets/images/202309/icon_nav_help.svg") center center no-repeat;
          }
        }
      }
      ul.btn-login {
        position: relative;
        display: flex;
        margin-left: 32px;
        padding-top: 14px;
        li {
          a {
            display: block;
            position: relative;
            width: 92px;
            height: 44px;
            border-radius: 50px;
            text-align: center;
            border: 1px solid #ddd;
            box-sizing: border-box;
            padding: 10px 0 10px;
            color: $color_1;
            letter-spacing: 0em;
            color: $color_1 !important;
          }
          &:nth-child(n+2) {
            margin-left: 8px;
          }
        }
        li.regist {
          a {
            background: #FAA14F;
            border: 1px solid #FAA14F;
            color: $color_2;
            color: $color_2 !important;
          }
        }
      }
      .usermenu {
        position: relative;
        width: 180px;
        border: 1px solid #ddd;
        border-radius: 50px;
        height: 48px;
        padding: 4px 4px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-top: 12px;
        margin-left: 32px;
        cursor: pointer;
        &:after {
          position: absolute;
          content: "";
          top: 20px;
          right: 17px;
          background: url("/assets/images/202309/icon_pulldown.svg") center center no-repeat;
          width: 8px;
          height: 5px;
          background-size: contain;
        }
        .image {
          position: relative;
          width: 38px;
          overflow: hidden;
          border-radius: 50%;
          flex-shrink: 0;
          margin-right: 8px;
          img {
            object-fit: cover;
            width: 38px;
            height: 38px;
          }
        }
        .name {
          font-size: 14px;
          letter-spacing: 0em;
        }
      }
      .usermenu-sp {
        position: relative;
        top: 13px;
        width: 40px;
        flex-shrink: 0;
        cursor: pointer;
        margin-left: 15px;
        a {
          display: block;
          width: 100%;
          height: 100%;
          overflow: hidden;
          border-radius: 50%;
          img {
            object-fit: cover;
            width: 40px;
            height: 40px;
          }
        }
      }
      .btnNavi {
        position: relative;
        top: 18px;
        width: 32px;
        height: 32px;
        box-sizing: border-box;
        margin-left: 12px;
        cursor: pointer;
        transition: opacity 0.3s cubic-bezier(0.19, 1, 0.22, 1);
        z-index: 10;
        padding: 6px 0;
        span {
          position: relative;
          display: block;
          width: 20px;
          height: 2px;
          background: #888;
          transition: all 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
          margin: 0 auto;
          &:nth-child(n+2) {
            margin: 6px auto 0;
          }
        }
      }
      .btnNavi.active {
        span {
          &:nth-child(1) {
            transform: translate(0px, 8px) rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: translate(0px, -8px) rotate(-45deg);
          }
        }
      }
    }
  }
}
#float-nav {
  position: fixed;
  top: 67px;
  right: 44px;
  width: 660px;
  background: #fff;
  z-index: 9999;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
  .top-box {
    position: relative;
    background: #F8F8F8;
    border-bottom: 1px solid #E4E4E4;
    display: flex;
    padding: 20px 20px 20px;
    box-sizing: border-box;
    justify-content: space-between;
    .user-box {
      position: relative;
      display: flex;
      align-self: start;
      align-items: center;
      .image {
        width: 48px;
        border-radius: 50%;
        overflow: hidden;
        img {
          object-fit: cover;
          width: 48px;
          height: 48px;
        }
      }
      .name-box {
        position: relative;
        margin-left: 16px;
        span {
          display: block;
          font-weight: bold;
          line-height: 1.3;
        }
        span.name {
          color: $color_1;
          font-size: 16px;
        }
        span.point {
          position: relative;
          margin-top: 2px;
          font-size: 16px;
        }
      }
    }
    .btn-box {
      display: flex;
      li {
        position: relative;
        width: 116px;
        height: 50px;
        &:nth-child(n+2) {
          margin-left: 6px;
        }
        a {
          display: flex;
          width: 100%;
          height: 100%;
          border: 1px solid #ddd;
          background: #fff;
          border-radius: 10px;
          font-size: 13px;
          line-height: 1.25;
          align-items: center;
          padding: 0 8px;
          box-sizing: border-box;
          .icon-box {
            position: relative;
            width: 24px;
            height: 24px;
            margin-right: 12px;
            span.icon {
              position: relative;
              background: transparent;
              font-size: 10px;
              margin-top: 4px;
              display: inline-block;
              padding: 2px 4px;
              border-radius: 3px;
              img {
                width: 100%;
                height: auto;
              }
            }
            span.icon.present {
              width: 24px;
              top: -6px;
            }
            span.icon.event {
              width: 21px;
              top: -4px;
            }
            span.icon.message {
              top: -1px;
              width: 20px;
            }
            span.newlabel {
              position: absolute;
              top: -7px;
              right: -10px;
              width: 19px;
              height: 19px;
              background: #FAA14F;
              border-radius: 50%;
              color: $color_2;
              font-weight: bold;
              font-size: 10px;
              text-align: center;
              padding: 4px 0;
              box-sizing: border-box;
            }
          }
          .text {
            position: relative;
            white-space: nowrap;
            letter-spacing: 0em;
          }
        }
      }
    }
  }
  .bottom-box {
    position: relative;
    padding: 35px 32px 32px;
    box-sizing: border-box;
    ul.member {
      display: flex;
      >li {
        width: 25%;
        h3 {
          position: relative;
          color: $color_1;
          font-weight: bold;
          font-size: 14px;
        }
        h3.active {
          &:after {
            opacity: 1;
          }
        }
        ul.nav-box {
          position: relative;
          margin: 20px auto 0;
          li {
            a {
              position: relative;
              display: block;
              span.newlabel {
                position: relative;
                top: -1px;
                width: 24px;
                height: 20px;
                background: #FEF4EB;
                display: inline-block;
                border-radius: 5px;
                text-align: center;
                color: $color_1;
                font-weight: bold;
                font-size: 12px;
                margin-left: 12px;
              }
            }
            &:nth-child(n+2) {
              margin-top: 14px;
            }
          }
        }
      }
    }
    ul.nomember {
      padding: 20px 0 0;
      li {
        position: relative;
        &:before {
          position: absolute;
          content: "";
          top: 10px;
          left: 0;
          width: 20px;
          height: 20px;
          background: #FEF4EB;
          border-radius: 50%;
        }
        &:after {
          position: absolute;
          content: "";
          top: 10px;
          left: 0;
          width: 20px;
          height: 20px;
          background: url("/assets/images/202309/icon_nav_event.svg") center center no-repeat;
        }
        a {
          position: relative;
          display: block;
        }
        &:nth-child(n+2) {
          margin-top: 14px;
        }
      }
      li.nav-event {
        &:after {
          background: url("/assets/images/202309/icon_nav_event.svg") center center no-repeat;
        }
      }
      li.nav-about {
        &:after {
          background: url("/assets/images/202309/icon_nav_about.png") center center no-repeat;
        }
      }
      li.nav-blog {
        &:after {
          background: url("/assets/images/202309/icon_nav_blog.svg") center center no-repeat;
        }
      }
      li.nav-help {
        &:after {
          background: url("/assets/images/202309/icon_nav_help.svg") center center no-repeat;
        }
      }
    }
  }
}
#float-nav.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}
footer {
  position: relative;
  width: 100%;
  padding: 64px 0 56px;
  ul.sns-box {
    display: flex;
    justify-content: center;
    align-items: end;
    li {
      position: relative;
      width: 38px;
      img {
        width: 100%;
        height: auto;
      }
      &:nth-child(n+2) {
        margin-left: 40px;
      }
    }
    li.x {
      width: 34px;
    }
  }
  nav {
    position: relative;
    margin: 40px auto 0;
    ul {
      display: flex;
      justify-content: center;
      li {
        position: relative;
        font-size: 13px;
        a {
          &:hover {
            text-decoration: none !important;
          }
        }
        &:nth-child(n+2) {
          margin-left: 24px;
        }
      }
    }
  }
  small.copyright {
    margin: 28px auto 0;
    display: block;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0em;
  }
}
#pagetop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  .btn-pagetop {
    position: relative;
    width: 62px;
    height: 62px;
    background: #FCFCFC;
    border-radius: 50%;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 19px 0;
    box-sizing: border-box;
    cursor: pointer;
    img {
      width: 19px;
      height: auto;
    }
  }
}
#pagetop.show {
  transition: all 0.5s cubic-bezier(0.33, 1, 0.68, 1);
  opacity: 1;
  transform: translateY(0px);
}
.btn-temp {
  position: relative;
  width: 280px;
  height: 70px;
  margin: 20px auto 0;
  a {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    padding: 21px 0;
    box-sizing: border-box;
    border-radius: 50px;
    text-align: center;
    font-size: 18px;
    background: #FAA14F;
    color: $color_2 !important;
    font-weight: bold;
    .icon {
      position: absolute;
      top: 12px;
      left: 16px;
      display: block;
      width: 38px;
      height: 38px;
      background: #fff;
      border-radius: 50%;
      //padding: 12px 0;
      box-sizing: border-box;
      margin-top: 4px;
      padding: 4px;
      svg {
        width: 12px;
        height: auto;
        .cls-1 {
          fill: #FAA14F;
          margin: 0 auto;
        }
      }
    }
    .text {
      padding-left: 12px;
    }
  }
}
.btn-temp.noborder {
  a {
    border: 0px !important;
  }
}
.btn-temp.white {
  a {
    background: #fff;
    border: 1px solid #ddd;
    color: $color_3 !important;
    .icon {
      background: #FAA14F;
      svg {
        .cls-1 {
          fill: #fff;
        }
      }
    }
  }
}
.btn-temp.txt-orange {
  a {
    color: $color_1 !important;
  }
}
body {
  #ripre-info {
    position: relative;
    width: 100%;
    background: #FAA14F;
    padding: 11px 0 10px;
    color: $color_2 !important;
    font-size: 16px;
    text-align: center;
    a {
      color: $color_2 !important;
    }
    .inner {
      width: 980px;
      margin: 0 auto;
      text-align: center;
    }
  }
  #wrapper {
    position: relative;
    // width: 100%;
    // margin: 0 auto !important;
    // max-width: none !important;
    overflow: hidden;
    #content {
      /* top 以外に設置したカルーセルバナー */
      section.sec-top-banners {
        padding: 0;
        display: flex;
        justify-content: center;
        .top-banners-box {
          .banners-carousel{
            position: relative;
            .carousel-arrow {
              position: absolute;
              width: 38px;
              height: 38px;
              top: 56px;
              display: none; // 初期は非表示。jsで表示処理を書く
            }
            .left-arrow {
              left: -38px;
            }
            .right-arrow {
              right: -38px;
            }
          }
        }
        @media screen and (max-width: $mobile-breakpoint) {
          margin-top: 65px;
          .top-banners-box {
            width: 100%;
            .top-banner {
              position: relative;
              display: flex;
              justify-content: center;
              margin-left: 7px;
              margin-right: 7px;
              img {
                height: 120px;
                width: 187.7px;
              }
            }
          }
        }
        @media screen and (min-width: $mobile-breakpoint) {
          margin-top: 20px;
          .top-banners-box {
            width: (234.63px + 14) * 3;
            .top-banner {
              margin-left: 7px;
              margin-right: 7px;
              img {
                height: 150px;
                width: 234.63px;
              }
            }
          }
        }
      }
    }
    main {
      position: relative;
      width: 100%;
      display: block;
      padding-top: 72px;
      section {
        padding: 60px 0 60px;
        .ttl-box {
          position: relative;
          text-align: center;
          h3 {
            font-size: 24px;
            color: $color_1;
            letter-spacing: 0.04em;
          }
          h2 {
            font-size: 32px;
            font-weight: bold;
            line-height: 1.4;
            margin-top: 2px;
          }
          span.line {
            display: block;
            width: 100px;
            height: 4px;
            background: #DFD9C5;
            border-radius: 5px;
            margin: 16px auto 0;
          }
        }
      }
      section.sec-main {
        position: relative;
        width: 100%;
        background: #F8F8F8;
        height: 550px;
        overflow: hidden;
        padding: 0;
        &:before {
          position: absolute;
          content: "";
          background: url("/assets/images/202309/frame_circle_white_bottom.png");
          width: 100%;
          height: 146px;
          bottom: 0;
          left: 0;
          background-size: 100% 100%;
          z-index: 10;
          pointer-events: none;
        }
        .l-box {
          position: absolute;
          top: calc(50% - 210px);
          left: calc(50% - 500px);
          text-align: center;
          h4 {
            font-size: 22px;
            font-weight: bold;
            opacity: 0;
            transform: translateY(25px);
            em {
              position: relative;
              top: 1px;
              margin: 0 4px 0 4px;
              font-size: 32px;
              color: $color_1;
            }
          }
          h3 {
            position: relative;
            width: 267px;
            margin: 24px auto 0;
            opacity: 0;
            transform: translateY(25px);
            img {
              width: 100%;
              height: auto;
            }
          }
          h5 {
            position: relative;
            margin: 20px auto 0;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.05em;
            opacity: 0;
            transform: translateY(25px);
          }
          .btn-regist {
            opacity: 0;
          }
          .btn-regist.display {
            animation: 1s cubic-bezier(0.65, 0, 0.35, 1) 0.7s forwards animation;
          }
        }
        .r-box {
          position: absolute;
          top: 0;
          left: calc(50% + 100px);
          li {
            position: absolute;
            width: 228px;
            opacity: 0;
            transform: scale(0.5);
            img {
              width: 100%;
              height: auto;
            }
            &:nth-child(1) {
              top: 40px;
              left: 229px;
            }
            &:nth-child(2) {
              top: 262px;
              left: 80px;
            }
          }
        }
        .image-main {
          opacity: 0;
          .image {
            position: absolute;
            left: calc(51.5% - 365px);
            bottom: 0px;
            width: 730px;
            margin: 0 auto;
            img {
              width: 100%;
              height: auto;
            }
          }
          .confetti {
            position: absolute;
            left: calc(51.5% - 365px);
            bottom: 0px;
            width: 730px;
            margin: 0 auto;
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        .btn-regist-sp {
          position: absolute;
          bottom: -44px;
          left: calc(50% - 140px);
          z-index: 11;
          width: 280px;
          opacity: 0;
        }
        .btn-regist-sp.display {
          animation: 1s cubic-bezier(0.65, 0, 0.35, 1) 0.7s forwards animation;
        }
      }
      section.sec-lead {
        position: relative;
        width: 100%;
        padding: 40px 0 48px;
        text-align: center;
        h2 {
          position: relative;
          font-size: 23px;
          text-align: center;
          font-weight: bold;
          display: inline-block;
          opacity: 0;
          &:before {
            position: absolute;
            content: "";
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 5px;
            background: #eee;
            border-radius: 5px;
          }
          em {
            color: $color_1;
            font-size: 33px;
          }
        }
      }
      section.sec-top-banners {
        padding: 0;
        display: flex;
        justify-content: center;
        .top-banners-box {
          .banners-carousel{
            .carousel-root {
              position: relative;
              .carousel-root{
                opacity: 0;
              }
              .carousel-root.display {
                animation: 1s cubic-bezier(0.65, 0, 0.35, 1) 0.7s forwards animation;
              }
              .carousel-arrow {
                position: absolute;
                width: 38px;
                height: 38px;
                top: 56px;
                display: none; // 初期は非表示。jsで表示処理を書く
              }
              .left-arrow {
                left: -38px;
              }
              .right-arrow {
                right: -38px;
              }
            }
          }
        }
      }
      section.sec-event {
        position: relative;
        background: #FDF5ED;
        padding: 50px 0 80px;
        &:before {
          position: absolute;
          content: "";
          background: url("/assets/images/202309/frame_circle_white_top.png");
          width: 100%;
          height: 146px;
          top: 0;
          left: 0;
          background-size: 100% 100%;
          pointer-events: none;
        }
        &:after {
          position: absolute;
          content: "";
          background: url("/assets/images/202309/frame_circle_white_bottom.png");
          width: 100%;
          height: 146px;
          bottom: 0;
          left: 0;
          background-size: 100% 100%;
          pointer-events: none;
        }
        ul.event-box {
          position: relative;
          width: 980px;
          margin: 50px auto 0;
          display: flex;
          flex-wrap: wrap;
          >li {
            position: relative;
            width: calc(25% - 10px);
            margin-bottom: 10px;
            border-radius: 12px;
            overflow: hidden;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
            &:not(:nth-child(4n+1)) {
              margin-left: 10px;
            }
            a {
              position: relative;
              width: 100%;
              height: 100%;
              background: #fff;
              display: block;
              padding: 20px 20px 24px;
              box-sizing: border-box;
              .image {
                position: relative;
                width: 100%;
                img {
                  position: inherit;
                  width: 100%;
                  height: auto;
                }
                .label {
                  position: inherit;
                  text-align: center;
                  margin: -12px auto;
                  border-radius: 50px;
                  color: #ffffff;
                  width: 108px;
                  height: 24px;
                  line-height: 24px;
                  font-size: 14px;
                  font-weight: 700;
                  background: rgba(170, 170, 170, 0.8);
                }
              }
              .text-box {
                position: relative;
                padding: 0 0;
                min-height: 143px;
                box-sizing: border-box;
                ul.label {
                  margin-top: 20px;
                  display: flex;

                  li {
                    position: relative;
                    width: calc(50% - 2px);
                    height: 28px;
                    text-align: center;
                    font-size: 10px;
                    background: #fff;
                    border-radius: 5px;
                    box-sizing: border-box;
                    //color: $color_2;
                    padding: 5px 0;
                    border: 1px solid #ddd;
                    letter-spacing: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &:nth-child(n+2) {
                      margin-left: 5px;
                    }
                  }

                  li.both {
                    background: #DCA09D;
                    border: 1px solid #DCA09D;
                    color: $color_2;
                  }

                  li.product {
                    background: #97AEBE;
                    border: 1px solid #97AEBE;
                    color: $color_2;
                  }

                  li.point {
                    background: #C3C9A7;
                    border: 1px solid #C3C9A7;
                    color: $color_2;
                  }

                  li.package {
                    background: #fff;
                    border: 1px solid #ddd;
                    color: $color_3;
                    padding-left: 12px;
                    box-sizing: border-box;

                    &:before {
                      position: absolute;
                      content: "";
                      top: 5px;
                      left: 3px;
                      background: url("/assets/images/202309/icon_package.svg") center center no-repeat;
                      width: 16px;
                      height: 16px;
                      background-size: contain;
                    }
                  }

                  li.shopping {
                    background: #fff;
                    border: 1px solid #ddd;
                    color: $color_3;
                    padding-left: 12px;
                    box-sizing: border-box;

                    &:before {
                      position: absolute;
                      content: "";
                      top: 5px;
                      left: 3px;
                      background: url("/assets/images/202309/icon_package.svg") center center no-repeat;
                      width: 16px;
                      height: 16px;
                      background-size: contain;
                    }
                  }

                  li.package.shopping {
                    &:before {
                      top: 5px;
                      left: 3px;
                      background: url("/assets/images/202309/icon_bag.svg") center center no-repeat;
                      width: 16px;
                      height: 16px;
                      background-size: contain;
                    }
                  }

                  li.shopping.shopping {
                    &:before {
                      top: 5px;
                      left: 3px;
                      background: url("/assets/images/202309/icon_bag.svg") center center no-repeat;
                      width: 16px;
                      height: 16px;
                      background-size: contain;
                    }
                  }

                  li.monitor {
                    background: #fff;
                    border: 1px solid #ddd;
                    color: $color_3;
                    padding-left: 12px;
                    box-sizing: border-box;

                    &:before {
                      position: absolute;
                      content: "";
                      top: 5px;
                      left: 3px;
                      background: url("/assets/images/202309/icon_present.svg") center center no-repeat;
                      width: 16px;
                      height: 16px;
                      background-size: contain;
                    }
                  }
                  li.odekake {
                    background: #fff;
                    border: 1px solid #ddd;
                    color: $color_3;
                    padding-left: 12px;
                    box-sizing: border-box;
                    &:before {
                      position: absolute;
                      content: "";
                      top: 5px;
                      left: 3px;
                      background: url("/assets/images/202309/icon_cart.svg") center center no-repeat;
                      width: 16px;
                      height: 16px;
                      background-size: contain;
                    }
                  }
                  li.enquete {
                    background: #fff;
                    border: 1px solid #ddd;
                    color: $color_3;
                    padding-left: 12px;
                    box-sizing: border-box;
                    &:before {
                      position: absolute;
                      content: "";
                      top: 5px;
                      left: 3px;
                      background: url("/assets/images/202309/icon_binder.svg") center center no-repeat;
                      width: 16px;
                      height: 16px;
                      background-size: contain;
                    }
                  }
                  li.odai {
                    background: #fff;
                    border: 1px solid #ddd;
                    color: $color_3;
                    padding-left: 12px;
                    box-sizing: border-box;
                    &:before {
                      position: absolute;
                      content: "";
                      top: 5px;
                      left: 3px;
                      background: url("/assets/images/202309/icon_megaphone.svg") center center no-repeat;
                      width: 16px;
                      height: 16px;
                      background-size: contain;
                    }
                  }
                }
                h4 {
                  position: relative;
                  margin: 16px 0 0;
                  font-weight: bold;
                }
                h3 {
                  margin: 4px 0 0;
                  font-size: 13px;
                  line-height: 1.5;
                }
                ul.info-box {
                  margin-top: 10px;
                  li {
                    position: relative;
                    padding-left: 20px;
                    letter-spacing: -0.02em;
                    &:nth-child(n+2) {
                      margin-top: 2px;
                    }
                    &:before {
                      position: absolute;
                      content: "";
                      top: 4px;
                      left: 0;
                      background: url("/assets/images/202309/icon_point.svg") center center no-repeat;
                      width: 14px;
                      height: 14px;
                      background-size: contain;
                    }
                  }
                  li.point {
                    font-weight: bold;
                  }
                  li.number {
                    color: $color_1;
                    font-size: 12px;
                    &:before {
                      top: 4px;
                      left: 0;
                      background: url("/assets/images/202309/icon_people.svg") center center no-repeat;
                      width: 14px;
                      height: 14px;
                      background-size: contain;
                    }
                    b {
                      font-weight: bold;
                    }
                    em {
                      font-size: 15px;
                      font-weight: bold;
                    }
                  }
                  li.period {
                    font-size: 12px;
                    &:before {
                      top: 5px;
                      left: -2px;
                      background: url("/assets/images/202309/icon_period.svg") center center no-repeat;
                      width: 17px;
                      height: 17px;
                      background-size: contain;
                    }
                    em {
                      font-size: 16px;
                      font-weight: bold;
                      margin-left: 2px;
                    }
                  }
                }
              }
              ul.media-box {
                display: flex;
                padding: 0 0;
                margin-top: 10px;
                li {
                  position: relative;
                  img {
                    width: 100%;
                    height: auto;
                  }
                  &:nth-child(n+2) {
                    margin-left: 11px;
                  }
                }
                li.instagram {
                  width: 18px;
                }
                li.tiktok {
                  width: 18px;
                }
                li.x {
                  width: 18px;
                }
                li.blog {
                  width: 32px;
                  top: 1px;
                }
              }
            }
          }
        }
        .btn-regist {
          position: relative;
          margin: 36px auto 0;
        }
      }
      section.sec-howto {
        position: relative;
        padding: 110px 0 100px;
        .step-box {
          position: relative;
          width: 900px;
          margin: 64px auto 0;
          ul {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            li {
              position: relative;
              width: calc(33.333% - 28px);
              text-align: center;
              &:nth-child(n+2) {
                margin-left: 40px;
              }
              img {
                width: 100%;
                height: auto;
              }
              .image-box {
                position: relative;
                .label {
                  position: absolute;
                  top: -20px;
                  right: -20px;
                  width: 90px;
                  height: 90px;
                  background: #FAA14F;
                  border-radius: 50%;
                  color: $color_2;
                  box-sizing: border-box;
                  padding: 18px 0 0;
                  &:before {
                    position: absolute;
                    content: "";
                    top: -4px;
                    left: -4px;
                    background: url("/assets/images/202309/icon_howto_step_frame.png") center center no-repeat;
                    width: 90px;
                    height: 90px;
                    background-size: contain;
                  }
                  span {
                    display: block;
                    font-weight: bold;
                    line-height: 1.1;
                    letter-spacing: 0.1em;
                  }
                  span.step {
                    font-size: 18px;
                  }
                  span.num {
                    font-size: 36px;
                  }
                }
              }
              .text-box {
                position: relative;
                margin: 40px auto 0;
                h3 {
                  position: relative;
                  font-size: 19px;
                  font-weight: bold;
                  height: 50px;
                }
                h3.padding {
                  top: 14px;
                }
                .p-box {
                  position: relative;
                  margin: 25px auto 0;
                  text-align: center;
                  line-height: 1.7;
                }
              }
            }
          }
          .note {
            margin: 36px auto 0;
            text-align: center;
            color: $color_1;
          }
        }
      }
      section.sec-excellentpost {
        position: relative;
        width: 100%;
        .base-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          ul.bg-l {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            li {
              &:nth-child(1) {
                position: absolute;
                top: -6%;
                left: calc(50% - 1200px);
                width: 999px;
                img {
                  width: 100%;
                  height: auto;
                }
              }
              &:nth-child(2) {
                position: absolute;
                top: 63%;
                left: calc(50% - 1000px);
                width: 562px;
                img {
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
          ul.bg-r {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            li {
              &:nth-child(1) {
                position: absolute;
                top: 0;
                left: calc(50% + 330px);
                width: 479px;
                img {
                  width: 100%;
                  height: auto;
                }
              }
              &:nth-child(2) {
                position: absolute;
                top: 33%;
                left: calc(50% + 330px);
                width: 795px;
                img {
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
        }
        .ttl-box {
          h2 {
            font-size: 26px;
          }
        }
        ul.post-box {
          position: relative;
          margin: 64px auto 0;
          z-index: 2;
          li {
            display: flex;
            justify-content: center;
            align-items: center;
            &:nth-child(n+2) {
              margin-top: 50px;
            }
            &:nth-child(2n+1) {
              .image-box {
                order: 2;
              }
              .text-box {
                margin-left: 0px;
                margin-right: 60px;
                order: 1;
              }
            }
            .image-box {
              width: 390px;
              flex-shrink: 0;
              .image {
                width: 100%;
                img {
                  width: 100%;
                  height: auto;
                }
              }
            }
            .image-box.review {
              width: 520px;
            }
            .image-box.media {
              width: 380px;
            }
            .text-box {
              margin-left: 60px;
              width: 380px;
              h4 {
                font-size: 28px;
                color: $color_1;
                font-weight: bold;
                border-bottom: 3px solid #D9D9D9;
                padding-bottom: 5px;
              }
              .p-box {
                margin: 16px auto 0;
                font-size: 14px;
                line-height: 1.8;
              }
            }
          }
        }
      }
      section.sec-user_voice {
        .effect {
          opacity: 0;
        }
        .base-box {
          display: grid;
          justify-content: center;
        }
      }
      section.sec-bestpost {
        position: relative;
        margin: 80px auto 0;
        padding: 40px 0 80px;
        background-color: #FDF1E1;
        display: flex;
        justify-content: center;
        .effect {
          opacity: 0;
        }
        &:before {
          position: absolute;
          content: "";
          background: url("/assets/images/202309/frame_circle_white_top.png");
          width: 100%;
          height: 146px;
          top: 0;
          left: 0;
          background-size: 100% 100%;
        }
        &:after {
          position: absolute;
          content: "";
          background: url("/assets/images/202309/frame_circle_white_bottom.png");
          width: 100%;
          height: 146px;
          bottom: 0;
          left: 0;
          background-size: 100% 100%;
        }
        .ttl-box {
          position: relative;
          ul.illust {
            position: absolute;
            left: calc(50% - 320px);
            width: 640px;
            li {
              position: absolute;
              bottom: 0;
              left: -20px;
              width: 190px;
              img {
                width: 100%;
                height: auto;
              }
              &:nth-child(2) {
                left: auto;
                right: 36px;
                width: 132px;
              }
            }
          }
        }
        .bestpost-box {
          position: relative;
          width: 892px; // (284*3+20*2)
          margin: 48px auto 0;
          .bestpost-list {
            position: relative;
            padding-top: 32px;
            article {
              position: relative;
              width: 284px;
              padding: 0 10px;
              a {
                display: block;
                background: #fff;
                border-radius: 10px;
                overflow: hidden;
                padding: 10px 10px 10px;
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                .head {
                  position: relative;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 0 10px;
                  gap: 10px;
                  .user {
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    gap: 10px;
                    .icon {
                      width: 36px;
                      height: 36px;
                      border-radius: 50%;
                      overflow: hidden;
                      padding: 0;
                      flex: none;
                      img {
                        width: 36px;
                        height: 36px;
                        display: block;
                      }
                    }
                    .name {
                      position: relative;
                      letter-spacing: 0em;
                      flex: 1 1 auto;
                    }
                  }
                  .icon-sns {
                    position: relative;
                    width: 20px;
                    a {
                      display: block;
                      width: 100%;
                      height: auto;
                    }
                    img {
                      width: 100%;
                      height: auto;
                    }
                  }
                  .icon-sns.blog {
                    width: 32px;
                  }
                }
                .thumb {
                  position: relative;
                  width: 100%;
                  margin-top: 10px;
                  text-align: center;
                  img {
                    width: 264px;
                    height: auto;
                  }
                  img.post-image-tiktok {
                    height: calc(264px * 16/9);
                  }
                  img.post-image-twitter, img.post-image-instagram {
                    height: 264px;
                    object-fit: cover;
                  }
                }
              }
            }
            .carousel-arrow {
              position: absolute;
              width: 38px;
              height: 38px;
              top: calc(50% - 38px / 2);
              display: none; // 初期は非表示。jsで表示処理を書く
            }
            .left-arrow {
              left: calc(-38px - 5px);
            }
            .right-arrow {
              right: calc(-38px - 5px);
            }
          }
          .label {
            display: flex;
            justify-content: center; /* 水平方向の中央揃え */
            align-items: center;
            padding-top: 80px;
          }
        }
        .bestpost-box.pc {
          flex-direction: column
        }
        .bestpost-box.sp {
          display: none;
        }
        .lead {
          position: relative;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          em {
            color: $color_1;
          }
          em.point {
            font-size: 38px;
            margin-left: 6px;
          }
          em.present {
            font-size: 29px;
            margin-left: 6px;
          }
        }
      }
      section.sec-campaign {
        position: relative;
        width: 1080px;
        margin: 80px auto 0;
        .container {
          width: 100%;
          display: flex;
          .effect {
            opacity: 0;
          }
          .l-box {
            position: relative;
            margin-right: 80px;
            z-index: 2;
            h5 {
              color: $color_1;
              font-size: 24px;
            }
            h4 {
              margin-top: 14px;
              font-size: 28px;
              font-weight: bold;
              line-height: 1.35;
            }
            .p-box {
              margin-top: 20px;
              p {
                font-size: 16px;
                line-height: 1.8;
              }
            }
            .btn-regist {
              position: relative;
              margin: 40px auto 0 0;
            }
          }
          .r-box {
            position: relative;
            width: 40%;
            flex-shrink: 0;
            .base {
              position: absolute;
              top: 0;
              left: 0;
              &:before {
                position: absolute;
                content: "";
                top: -40px;
                left: -80px;
                background: url("/assets/images/202309/bg_amazongift.png") center center no-repeat;
                width: 620px;
                height: 527px;
                background-size: contain;
                z-index: 0;
              }
            }
            .image {
              position: relative;
              margin-top: 40px;
              width: 100%;
              img {
                width: 100%;
                height: auto;
              }
            }
            p.note {
              position: relative;
              font-size: 11px;
              color: $color_4;
              margin-top: 20px;
              a {
                text-decoration: underline !important;
                color: $color_4 !important;
              }
            }
          }
        }
      }
      section.sec-faq {
        position: relative;
        background: #F4F3EE;
        margin: 150px auto 0;
        padding: 70px 0 140px;
        &:before {
          position: absolute;
          content: "";
          background: url("/assets/images/202309/frame_circle_white_top.png");
          width: 100%;
          height: 146px;
          top: 0;
          left: 0;
          background-size: 100% 100%;
        }
        &:after {
          position: absolute;
          content: "";
          background: url("/assets/images/202309/frame_circle_white_bottom.png");
          width: 100%;
          height: 146px;
          bottom: 0;
          left: 0;
          background-size: 100% 100%;
        }
        .faq-box {
          position: relative;
          margin: 32px auto 0;
          width: 980px;
          li {
            position: relative;
            .question {
              padding: 0 0 0 70px;
              &:before {
                position: absolute;
                content: "Q";
                top: 0;
                left: 0;
                width: 60px;
                height: 60px;
                background: #FAA14F;
                color: $color_2;
                border-radius: 50%;
                text-align: center;
                box-sizing: border-box;
                padding: 8px 0 0;
                font-size: 28px;
                font-weight: bold;
              }
              p {
                font-size: 20px;
                font-weight: bold;
                padding: 15px 0 0;
              }
            }
            .answer {
              position: relative;
              margin-top: 32px;
              width: 100%;
              background: #fff;
              border-radius: 30px;
              padding: 30px 40px 30px 70px;
              box-sizing: border-box;
              line-height: 1.8;
              font-size: 15px;
              &:before {
                position: absolute;
                content: "A";
                top: 11px;
                left: 18px;
                width: 28px;
                height: 28px;
                color: $color_1;
                border-radius: 50%;
                text-align: center;
                box-sizing: border-box;
                padding: 8px 0 0;
                font-size: 28px;
                font-weight: bold;
              }
            }
            &:nth-child(n+2) {
              margin-top: 40px;
            }
          }
        }
      }
      section.sec-regist {
        position: relative;
        width: calc(100% - 80px);
        max-width: 1200px;
        margin: 60px auto;
        background: #FAA14F;
        border-radius: 50px;
        color: $color_2;
        padding: 0;
        .container {
          position: relative;
          display: flex;
          padding: 40px 60px 40px 60px;
          align-items: center;
          box-sizing: border-box;
          justify-content: space-between;
          .logo-box {
            width: 240px;
            flex-shrink: 0;
            .baloon {
              position: relative;
              background: #fff;
              color: $color_1;
              border-radius: 50px;
              padding: 3px 20px 5px;
              font-weight: bold;
              text-align: center;
              &:after {
                position: absolute;
                content: "";
                bottom: -9px;
                right: 38px;
                background: url("/assets/images/202309/icon_baloon.png") top center no-repeat;
                width: 9px;
                height: 9px;
                background-size: contain;
              }
              em {
                position: relative;
                top: 1px;
                font-size: 20px;
                margin: 0 2px 0 0;
              }
            }
            .logo {
              position: relative;
              margin: 28px auto 0;
              width: 180px;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
          .text-box {
            flex: 1;
            margin-left: 40px;
            h4 {
              font-size: 24px;
              font-weight: bold;
            }
            p {
              font-size: 16px;
              line-height: 1.8;
              margin-top: 7px;
            }
          }
          .btn-box {
            width: 280px;
            flex-shrink: 0;
            margin-left: 24px;
            .btn-regist {
              position: relative;
              margin: 0;
            }
          }
        }
      }
      section.sec-banner {
        position: relative;
        width: 100%;
        background: #F5F5F5;
        padding: 50px 0 50px;
        ul {
          width: 980px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          li {
            width: calc(33.333% - 16px);
            &:nth-child(n+2) {
              margin-left: 24px;
            }
            a {
              display: block;
              width: 100%;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}
body.top {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}
@media screen and (max-width: $mobile-breakpoint) {
  .pc {
    display: none !important;
  }
  .sp {
    display: block !important;
  }
  body.login {
    header {
      .btn-login {
        display: none !important;
      }
    }
  }
  body.logout {
    header {
      .usermenu {
        display: none !important;
      }
      .usermenu-sp {
        display: none !important;
      }
    }
  }
  header {
    height: 64px;
    h1 {
      width: 79px;
      top: 17px;
      left: 12px;
    }
    .r-box {
      right: 15px;
      nav {
        align-items: start;
        ul.gnav-li {
          display: none;
        }
        ul.btn-login {
          li {
            a {
              width: 76px;
              height: 36px;
              font-size: 12px;
              padding: 8px 0;
            }
            &:nth-child(n+2) {
              margin-left: 6px;
            }
          }
        }
        .usermenu {
          display: none;
        }
        .btnNavi {
          span {
            width: 20px;
            height: 2px;
          }
        }
      }
    }
  }
  #header {
    height: 64px;
    h1 {
      width: 79px;
      top: 17px;
      left: 12px;
    }
    .r-box {
      right: 15px;
      nav {
        align-items: start;
        ul.gnav-li {
          display: none;
        }
        ul.btn-login {
          li {
            a {
              width: 76px;
              height: 36px;
              font-size: 12px;
              padding: 8px 0;
            }
            &:nth-child(n+2) {
              margin-left: 6px;
            }
          }
        }
        .usermenu {
          display: none;
        }
        .btnNavi {
          span {
            width: 20px;
            height: 2px;
          }
        }
      }
    }
  }
  #float-nav {
    top: 64px;
    width: 100%;
    height: calc(100% - 64px);
    right: 0;
    overflow: scroll;
    border-radius: 0px;
    .top-box {
      display: block;
      padding: 20px 12px 16px;
      .user-box {
        .name-box {
          span {
            display: inline-block;
          }
        }
      }
      .btn-box {
        margin: 12px auto 0;
        li {
          width: calc(33.33% - 4px);
          height: 44px;
          &:nth-child(n+2) {
            margin-left: 4px;
          }
          a {
            font-size: 11px;
            padding: 0 7px;
            .icon-box {
              span.newlabel {
                padding: 4px 0;
              }
            }
            .text {
              text-align: center;
              top: 1px;
              left: 3%;
            }
          }
        }
      }
    }
    .bottom-box {
      padding: 20px 20px 32px;
      ul.member {
        display: block;
        margin: -1px 0 0;
        >li {
          width: 100%;
          h3 {
            border-bottom: 1px solid #D9D9D9;
            top: 1px;
            padding: 10px 0px 9px;
            font-size: 14px;
            &:before {
              position: absolute;
              content: "";
              top: 20px;
              right: 11px;
              width: 9px;
              height: 1px;
              background: #cccccc;
            }
            &:after {
              position: absolute;
              content: "";
              top: 20px;
              right: 11px;
              width: 9px;
              height: 1px;
              background: #cccccc;
              transform: rotate(90deg);
              opacity: 0;
              transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
            }
          }
          ul.nav-box {
            margin: 0;
            li {
              border-top: 1px solid #D9D9D9;
              &:last-child {
                border-bottom: 1px solid #D9D9D9;
              }
              a {
                padding: 10px 0px;
                font-size: 13px;
                &:after {
                  position: absolute;
                  content: "";
                  top: 15px;
                  right: 12px;
                  background: url("/assets/images/202309/icon_spnav_link.svg") center center no-repeat;
                  width: 6px;
                  height: 10px;
                  background-size: contain;
                }
                span.newlabel {
                  margin-left: 8px;
                }
              }
              &:nth-child(n+2) {
                margin: 0;
              }
            }
          }
          ul.nav-box.hide {
            display: none;
          }
        }
      }
      ul.nomember {
        li {
          border-top: 1px solid #D9D9D9;
          &:last-child {
            border-bottom: 1px solid #D9D9D9;
          }
          a {
            padding: 10px 0px 10px 26px;
            font-size: 13px;
            box-sizing: border-box;
            &:after {
              position: absolute;
              content: "";
              top: 15px;
              right: 12px;
              background: url("/assets/images/202309/icon_spnav_link.svg") center center no-repeat;
              width: 6px;
              height: 10px;
              background-size: contain;
            }
          }
          &:nth-child(n+2) {
            margin: 0;
          }
        }
      }
    }
  }
  footer {
    padding: 64px 0 40px;
    nav {
      width: 90%;
      ul {
        flex-wrap: wrap;
        li {
          margin: 0 13px 8px;
          &:nth-child(n+2) {
            margin: 0 13px 8px;
          }
        }
      }
    }
  }
  #pagetop {
    bottom: 10px;
    right: 10px;
    .btn-pagetop {
      width: 48px;
      height: 48px;
      padding: 10px 0;
      box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
      img {
        width: 14px;
        height: auto;
      }
    }
  }
  .btn-temp {
    width: 240px;
    height: 52px;
    a {
      padding: 13px 0;
      font-size: 15px;
      .icon {
        top: 5px;
        left: 10px;
        width: 32px;
        height: 32px;
        //padding: 9px 0;
        margin-top: 4px;
        padding: 4px;
        svg {
          width: 10px;
        }
      }
    }
  }
  body {
    #ripre-info {
      font-size: 14px;
      line-height: 1.4;
      padding: 12px 0 12px;
      .inner {
        width: calc(100% - 40px);
      }
    }
    #wrapper {
      main {
        padding-top: 64px;
        section {
          padding: 60px 0 60px;
          .ttl-box {
            h3 {
              font-size: 20px;
            }
            h2 {
              font-size: 22px;
              margin-top: 1px;
            }
            span.line {
              width: 80px;
              height: 3px;
              margin: 10px auto 0;
            }
          }
        }
        section.sec-main {
          height: 450px;
          overflow: visible;
          &:before {
            background: url("/assets/images/202309/frame_circle_white_bottom_sp.png");
            height: 28px;
            background-size: 100% 100%;
          }
          .l-box {
            top: 16px;
            left: 0;
            width: 100%;
            h4 {
              font-size: 17px;
              em {
                font-size: 30px;
                top: 2px;
              }
            }
            h3 {
              width: 160px;
              margin: 5px auto 0;
            }
            h5 {
              font-size: 16px;
              margin: 4px auto 0;
            }
          }
          .r-box {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            li {
              width: 36vw;
              max-width: 135px;
              &:nth-child(1) {
                top: 42%;
                left: 1.5%;
              }
              &:nth-child(2) {
                top: 64%;
                left: auto;
                right: 1.5%;
              }
            }
          }
          .image-main {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            .image {
              position: absolute;
              width: 370px;
              bottom: 0;
              left: calc(48% - 190px);
            }
            .confetti {
              position: absolute;
              width: 370px;
              bottom: 0;
              left: calc(48% - 190px);
            }
          }
        }
        section.sec-lead {
          padding: 40px 0 38px;
          &.login {
            padding-top: 38px;
          }
          h2 {
            font-size: 16px;
            font-size: clamp(14px, 4.5vw, 16px);
            letter-spacing: 0em;
            &:before {
              height: 3px;
              bottom: -4px;
            }
            em {
              font-size: 22px;
              font-size: clamp(18px, 6.2vw, 22px);
            }
          }
        }
        section.sec-top-banners {
          margin-top: 65px;
          .top-banners-box {
            width: 100%;
            .top-banner {
              position: relative;
              display: flex;
              justify-content: center;
              margin-left: 7px;
              margin-right: 7px;
              img {
                height: 120px;
                width: 187.7px;
              }
            }
          }
        }
        section.sec-event {
          &:before {
            background: url("/assets/images/202309/frame_circle_white_top_sp.png");
            height: 28px;
            background-size: 100% 100%;
          }
          &:after {
            background: url("/assets/images/202309/frame_circle_white_bottom_sp.png");
            height: 28px;
            background-size: 100% 100%;
          }
          ul.event-box {
            width: calc(100% - 20px);
            display: block;
            margin: 36px auto 0;
            >li {
              width: 100%;
              max-width: 640px;
              margin: 0 auto 8px;
              margin-bottom: 8px;
              border-radius: 9px;
              &:not(:nth-child(4n+1)) {
                margin: 0 auto 8px;
              }
              a {
                display: flex;
                padding: 15px 15px 15px;
                .image {
                  width: 108px;
                  flex-shrink: 0;
                  margin-right: 18px;
                  .label {
                    margin: -10.5px auto;
                    width: 78px;
                    height: 21px;
                    line-height: 21px;
                    font-size: 12px;
                    // padding-top: 60px;
                  }
                }
                .text-box {
                  flex: 1;
                  ul.label {
                    margin: 0;
                    li {
                      width: calc(50% - 0px);
                      height: 20px;
                      border-radius: 4px;
                      padding: 2px 0;
                      &:nth-child(n+2) {
                        margin-left: 8px;
                      }
                    }
                    li.package {
                      &:before {
                        top: 2px;
                        left: 5px;
                        width: 14px;
                        height: 14px;
                      }
                    }
                    li.shopping {
                      &:before {
                        top: 2px;
                        left: 5px;
                        width: 14px;
                        height: 14px;
                      }
                    }
                    li.package.shopping {
                      &:before {
                        top: 2px;
                        left: 4px;
                        width: 14px;
                        height: 14px;
                      }
                    }
                    li.shopping.shopping {
                      &:before {
                        top: 2px;
                        left: 4px;
                        width: 14px;
                        height: 14px;
                      }
                    }
                    li.monitor {
                      &:before {
                        top: 2px;
                        left: 5px;
                        width: 14px;
                        height: 14px;
                      }
                    }
                    li.odekake {
                      &:before {
                        top: 2px;
                        left: 5px;
                        width: 14px;
                        height: 14px;
                      }
                    }
                    li.enquete {
                      &:before {
                        top: 2px;
                        left: 5px;
                        width: 14px;
                        height: 14px;
                      }
                    }
                    li.odai {
                      &:before {
                        top: 2px;
                        left: 5px;
                        width: 14px;
                        height: 14px;
                      }
                    }
                  }
                  h4 {
                    font-size: 12px;
                    margin: 9px 0 0;
                  }
                  h3 {
                    font-size: 11px;
                    margin: 1px 0 0;
                  }
                  ul.info-box {
                    margin-top: 8px;
                    li {
                      padding-left: 16px;
                      &:nth-child(n+2) {
                        margin-top: 1px;
                      }
                      &:before {
                        top: 3px;
                        width: 12px;
                        height: 12px;
                      }
                    }
                    li.point {
                      font-size: 12px;
                    }
                    li.number {
                      font-size: 10px;
                      &:before {
                        top: 3px;
                        width: 12px;
                        height: 12px;
                      }
                      em {
                        font-size: 12px;
                      }
                    }
                    li.period {
                      font-size: 10px;
                      &:before {
                        top: 4px;
                        left: -1px;
                        width: 14px;
                        height: 14px;
                      }
                      em {
                        font-size: 12px;
                      }
                    }
                  }
                }
                ul.media-box {
                  position: absolute;
                  bottom: 16px;
                  left: 16px;
                  li.instagram {
                    width: 13px;
                  }
                  li.tiktok {
                    width: 13px;
                  }
                  li.x {
                    width: 13px;
                  }
                  li.blog {
                    width: 23px;
                  }
                  li {
                    &:nth-child(n+2) {
                      margin-left: 8px;
                    }
                  }
                }
              }
            }
          }
          .btn-regist {
            margin: 50px auto 0;
          }
        }
        section.sec-howto {
          padding: 50px 0 100px;
          .step-box {
            width: 100%;
            ul {
              display: block;
              li {
                width: 280px;
                margin: 0 auto;
                &:nth-child(n+2) {
                  margin: 56px auto 0;
                }
                .image-box {
                  width: 200px;
                  margin: 0 auto;
                  .image {
                    width: 100%;
                    img {
                      width: 100%;
                      height: auto;
                    }
                  }
                  .label {
                    top: -15px;
                    right: -15px;
                    width: 65px;
                    height: 65px;
                    padding: 12px 0 0;
                    &:before {
                      top: -3px;
                      left: -3px;
                      width: 65px;
                      height: 65px;
                    }
                    span.step {
                      font-size: 13px;
                    }
                    span.num {
                      font-size: 28px;
                    }
                  }
                }
                .text-box {
                  margin: 20px auto 0;
                  h3 {
                    height: auto;
                    font-size: 18px;
                    line-height: 1.4;
                  }
                  h3.padding {
                    top: 0px;
                  }
                  .p-box {
                    margin: 12px auto 0;
                    line-height: 1.6;
                  }
                }
              }
            }
            .note {
              margin: 32px auto 0;
              font-size: clamp(12px, 4vw, 14px);
            }
          }
        }
        section.sec-excellentpost {
          padding: 0px 0 40px;
          .base-box {
            ul.bg-l {
              li {
                &:nth-child(1) {
                  top: 6%;
                  left: 0;
                  width: 18%;
                }
                &:nth-child(2) {
                  top: 82%;
                  left: 0;
                  width: 37%;
                }
              }
            }
            ul.bg-r {
              li {
                &:nth-child(1) {
                  top: -2%;
                  left: auto;
                  right: 0;
                  width: 28%;
                }
                &:nth-child(2) {
                  top: 62%;
                  left: auto;
                  right: 0;
                  width: 29%;
                }
              }
            }
          }
          .ttl-box {
            h2 {
              font-size: 20px;
            }
          }
          ul.post-box {
            margin: 36px auto 0;
            li {
              flex-wrap: wrap;
              width: calc(100% - 60px);
              max-width: 480px;
              margin: 0 auto;
              &:nth-child(2n+1) {
                .text-box {
                  width: 100%;
                  margin: 0;
                }
              }
              .image-box {
                width: 100%;
                margin: 20px auto 0;
                order: 2;
              }
              .image-box.review {
                width: 100%;
              }
              .image-box.media {
                width: 84%;
              }
              .text-box {
                width: 100%;
                margin: 0;
                order: 1;
                h4 {
                  font-size: 20px;
                  padding-bottom: 7px;
                }
                .p-box {
                  margin: 12px auto 0;
                  line-height: 1.6;
                  br {
                    display: none;
                  }
                }
              }
            }
          }
        }
        section.sec-user_voice {
          .base-box {
            grid-template-rows: 190px 190px 190px;
            grid-template-columns: 167px 167px;
            .voice {
              width: 160px;
              height: 168px;
              margin: 22px 7px;
              img {
                width: 160px;
                height: 168px;
              }
            }
            .voice:nth-child(1) {
              grid-row: 1;
              grid-column: 1;
            }
            .voice:nth-child(2) {
              grid-row: 1;
              grid-column: 2;
            }
            .voice:nth-child(3) {
              grid-row: 2;
              grid-column: 1;
            }
            .voice:nth-child(4) {
              grid-row: 2;
              grid-column: 2;
            }
            .voice:nth-child(5) {
              grid-row: 3;
              grid-column: 1;
            }
            .voice:nth-child(6) {
              grid-row: 3;
              grid-column: 2;
            }
          }
        }
        section.sec-bestpost {
          padding: 64px 0 80px;
          margin: 32px auto 0;
          &:before {
            background: url("/assets/images/202309/frame_circle_white_top_sp.png");
            height: 28px;
            background-size: 100% 100%;
          }
          &:after {
            background: url("/assets/images/202309/frame_circle_white_bottom_sp.png");
            height: 28px;
            background-size: 100% 100%;
          }
          .ttl-box {
            ul.illust {
              width: 100%;
              left: 0;
              bottom: -10px;
              li {
                width: 25vw;
                left: -2%;
                &:nth-child(2) {
                  width: 18vw;
                  right: 4%;
                }
              }
            }
          }
          .bestpost-box {
            margin: 36px auto 0;
            width: 100%;
            display: block;
            article {
              width: 275px;
              margin: 0 auto;
            }
          }
          .bestpost-box.sp {
            display: block;
          }
          .bestpost-box.pc {
            display: none;
          }
          .lead {
            margin-top: 30px;
            font-size: 17px;
            line-height: 1.35;
            em.point {
              font-size: 26px;
            }
            em.present {
              font-size: 20px;
              margin: 0;
            }
          }
        }
        section.sec-campaign {
          width: 100%;
          margin: 60px auto 0;
          .container {
            flex-wrap: wrap;
            .l-box {
              width: 100%;
              padding: 0 30px;
              box-sizing: border-box;
              margin: 28px auto 0;
              z-index: 2;
              order: 2;
              h5 {
                font-size: 18px;
              }
              h4 {
                font-size: clamp(18px, 5vw, 20px);
                margin-top: 10px;
                line-height: 1.45;
              }
              .p-box {
                margin-top: 12px;
                p {
                  font-size: 14px;
                  line-height: 1.6;
                }
              }
              .btn-regist {
                margin: 20px auto 0;
              }
              .note {
                margin: 28px auto 0;
                font-size: 11px;
                color: $color_4;
                a {
                  text-decoration: underline !important;
                  color: $color_4 !important;
                }
              }
            }
            .r-box {
              order: 1;
              width: 100%;
              .base {
                width: 100%;
                &:before {
                  top: -60px;
                  left: calc(60% - 200px);
                  width: 400px;
                  height: 350px;
                }
              }
              .image {
                width: 75%;
                max-width: 400px;
                margin: 0 auto;
              }
            }
          }
        }
        section.sec-faq {
          margin: 20px auto 0;
          padding: 58px 10px 100px;
          box-sizing: border-box;
          &:before {
            background: url("/assets/images/202309/frame_circle_white_top_sp.png");
            height: 28px;
            background-size: 100% 100%;
          }
          &:after {
            background: url("/assets/images/202309/frame_circle_white_bottom_sp.png");
            height: 28px;
            background-size: 100% 100%;
          }
          .faq-box {
            width: 100%;
            li {
              .question {
                padding: 0 0 0 60px;
                &:before {
                  width: 46px;
                  height: 46px;
                  font-size: 22px;
                  padding: 6px 0 0;
                }
                p {
                  font-size: 16px;
                  line-height: 1.4;
                  padding: 12px 0 0;
                }
              }
              .answer {
                margin-top: 18px;
                border-radius: 20px;
                padding: 20px 20px 20px 60px;
                font-size: 13px;
                line-height: 1.6;
                &:before {
                  font-size: 22px;
                  left: 10px;
                }
              }
              &:nth-child(n+2) {
                margin-top: 30px;
              }
            }
          }
        }
        section.sec-regist {
          width: calc(100% - 20px);
          border-radius: 30px;
          .container {
            display: block;
            padding: 30px 20px 32px;
            .logo-box {
              width: 100%;
              .baloon {
                font-size: 12px;
                padding: 2px 20px 3px;
                width: 220px;
                box-sizing: border-box;
                margin: 0 auto;
                em {
                  font-size: 17px;
                }
              }
              .logo {
                width: 148px;
                margin: 16px auto 0;
              }
            }
            .text-box {
              margin-top: 12px;
              margin-left: 0;
              h4 {
                font-size: 18px;
              }
              p {
                font-size: 14px;
                margin-top: 5px;
                line-height: 1.75;
              }
            }
            .btn-box {
              max-width: 280px;
              width: 100%;
              margin: 20px auto 0;
              .btn-regist {
                width: 100%;
                margin: 28px auto 0;
              }
            }
          }
        }
        section.sec-banner {
          padding: 44px 0 44px;
          ul {
            width: 280px;
            display: block;
            li {
              width: 100%;
              &:nth-child(n+2) {
                margin: 14px auto 0;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: $mobile-breakpoint) {
  header {
    a {
      transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      &:hover {
        text-decoration: none;
        color: $color_1;
      }
    }
    .r-box {
      nav {
        ul.btn-login {
          li.login {
            a {
              transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
              &:hover {
                background: #F8F8F8;
              }
            }
          }
          li.regist {
            a {
              transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
        .usermenu {
          transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
          &:hover {
            background: #F8F8F8;
          }
        }
      }
    }
  }
  #header {
    a {
      transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      &:hover {
        text-decoration: none;
        color: $color_1;
      }
    }
    .r-box {
      nav {
        ul.btn-login {
          li.login {
            a {
              transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
              &:hover {
                background: #F8F8F8;
              }
            }
          }
          li.regist {
            a {
              transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
        .usermenu {
          transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
          &:hover {
            background: #F8F8F8;
          }
        }
      }
    }
  }
  #float-nav {
    .top-box {
      .btn-box {
        li {
          a {
            transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
            &:hover {
              background: #F8F8F8;
            }
          }
        }
      }
    }
    .bottom-box {
      ul.member {
        >li {
          ul.nav-box {
            li {
              a {
                transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
                &:hover {
                  text-decoration: none;
                  color: $color_1 !important;
                }
              }
            }
          }
        }
      }
      ul.nomember {
        li {
          a {
            transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
            &:hover {
              text-decoration: none;
              color: $color_1 !important;
            }
          }
        }
      }
    }
  }
  footer {
    a {
      transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      &:hover {
        text-decoration: none;
        color: $color_1 !important;
      }
    }
    ul.sns-box {
      li {
        a {
          transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
          &:hover {
            opacity: 0.6;
            img {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  #pagetop {
    .btn-pagetop {
      transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .btn-temp {
    a {
      transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      &:hover {
        opacity: 0.7;
      }
    }
  }
  body {
    #ripre-info {
      a {
        transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
        &:hover {
          opacity: 0.6;
        }
      }
    }
    #wrapper {
      main {
        section.sec-top-banners {
          margin-top: 20px;
          .top-banners-box {
            width: (234.63px + 14) * 3;
            .top-banner {
              margin-left: 7px;
              margin-right: 7px;
              img {
                height: 150px;
                width: 234.63px;
              }
            }
          }
        }
        section.sec-event {
          ul.event-box {
            >li {
              transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
              &:hover {
                opacity: 0.75;
              }
            }
          }
        }
        section.sec-campaign {
          .container {
            .r-box {
              p.note {
                a {
                  transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
                  &:hover {
                    opacity: 0.6;
                  }
                }
              }
            }
          }
        }
        section.sec-user_voice {
          .base-box {
            grid-template-rows: 188px 188px;
            grid-template-columns: 330px 330px 330px;
            .voice {
              width: 323px;
              height: 170px;
              margin: 19px 7.5px;
              img {
                width: 323px;
                height: 170px;
              }
            }
            .voice:nth-child(1) {
              grid-row: 1;
              grid-column: 1;
            }
            .voice:nth-child(2) {
              grid-row: 1;
              grid-column: 2;
            }
            .voice:nth-child(3) {
              grid-row: 1;
              grid-column: 3;
            }
            .voice:nth-child(4) {
              grid-row: 2;
              grid-column: 1;
            }
            .voice:nth-child(5) {
              grid-row: 2;
              grid-column: 2;
            }
            .voice:nth-child(6) {
              grid-row: 2;
              grid-column: 3;
            }
          }
        }
        section.sec-banner {
          ul {
            li {
              a {
                transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
                &:hover {
                  opacity: 0.6;
                  img {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $mobile-breakpoint) and (min-width: $mobile-breakpoint) {
  body {
    #wrapper {
      main {
        section.sec-campaign {
          .container {
            .l-box {
              .note {
                a {
                  transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
                  &:hover {
                    opacity: 0.6;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
